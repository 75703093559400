<template>
    <div class="outerBox">
		<div class="avatarPhotoBox">
			<div class="nameBoard">
				<img src="@/assets/page2/fengcai.png" class="nameBoardFrame" alt="">
				<div class="personName">{{memberInfo.memberName}}</div>
			</div>
			<div class="avatarPhoto">
				<img class="photo" :src="memberInfo.avatarUrl" alt="">
				<!-- 党员头像图片通过网络Url链接加载，非静态图像不从本地加载，直接赋值远程地址 -->
				<div class="photoCorner" style="border-width: 1.5px 0 0 1.5px; left: 0; top: 0;"></div>
				<div class="photoCorner" style="border-width: 1.5px 1.5px 0 0; right: 0; top: 0;"></div>
				<div class="photoCorner" style="border-width: 0 0 1.5px 1.5px; left: 0; bottom: 0;"></div>
				<div class="photoCorner" style="border-width: 0 1.5px 1.5px 0; right: 0; bottom: 0"></div>
			</div>
		</div>
		<div class="radarChartOuterBox">
			<div class="radarChartBox">
				<img src="@/assets/page2/radarChart.png" class="radarChartBg" alt="">
				<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%" height="100%" viewBox="0 0 100 100" class="dynamicRadarSvg">
					<path :d="svgPath" fill="#ff3b3b" fill-opacity="0.4"></path>
				</svg>
				<span class="itemName" style="left: -42.42%; top: 13.64%">奉献意识</span>
				<span class="itemValue" style="left: -42.42%; top: 0">{{memberInfo.scores.dedication}}</span>
				<span class="itemName" style="right: -42.42%; top: 13.64%">日常行为</span>
				<span class="itemValue" style="right: -42.42%; top: 0" >{{memberInfo.scores.behavior}}</span>
				<span class="itemName" style="left: -42.42%; bottom: 5.3%">模范作用</span>
				<span class="itemValue" style="left: -42.42%; bottom: 21.21%">{{memberInfo.scores.exemplar}}</span>
				<span class="itemName" style="right: -42.42%; bottom: 5.3%">理想信念</span>
				<span class="itemValue" style="right: -42.42%; bottom: 21.21%">{{memberInfo.scores.belief}}</span>
			</div>
			<div class="radarChartTextBoxTop" style="top: 0">
				<span class="itemNameTop">理论水平</span>
				<span class="itemValueTop">{{memberInfo.scores.theoryLevel}}</span>
			</div>
			<div class="radarChartTextBoxTop" style="bottom: 0">
				<span class="itemNameTop">业务能力</span>
				<span class="itemValueTop">{{memberInfo.scores.ability}}</span>
			</div>
		</div>
	</div>
</template>

<style lang="less" scoped>
.outerBox {
	width: 100%;
	height: 19.26vh;
	display: flex;
	align-items: center;
	border: 1px solid;
	border-image: linear-gradient(180deg, rgba(255, 59, 59, 0.4), rgba(255, 59, 59, 0)) 1 1;

	.avatarPhotoBox {
		width: 24.21%;
		height: 84.62%;
		margin-left: 4.21%;

		.nameBoard {
			width: 100%;
			height: 15.91%;
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;

			.nameBoardFrame {
				position: absolute;
				width: 100%;
				height: 100%;
			}
			.personName {
				width: 100%;
				height: 2.04vh;
				font-size: 1.48vh;
				font-family: 'pingfangM', sans-serif;
				font-weight: 400;
				line-height: 2.04vh;
				background: linear-gradient(180deg, #FFFFFF 42%, #FFD03B 100%);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				display: flex;
				align-items: center;
				justify-content: center;
			}

		}

		.avatarPhoto {
			width: 100%;
			height: 79.54%;
			border: 1px solid #ff3b3b;
			margin-top: 4.55%;
			position: relative;

			.photo {
				position: absolute;
				width: 100%;
				height: 100%;
			}

			.photoCorner {
				position: absolute;
				border-color: #ff3b3b;
				border-style: solid;
				width: 10.87%;
				aspect-ratio: 1/1;
			}
		}
	}

	.radarChartOuterBox {
		width: 63.16%;
		height: 84.62%;
		margin-left: 4.21%;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		
		.radarChartBox {
			height: 75%;
			aspect-ratio: 1/1;
			position: relative;
			
			.radarChartBg {
				position: absolute;
				width: 100%;
				height: 100%;
			}
			
			.dynamicRadarSvg {
				width: 100%;
				height: 100%;
				position: absolute;
			}
			
			.itemName {
				position: absolute;
				height: 1.944vh;
				font-size: 1.3vh;
				line-height: 1.944vh;
				font-weight: 400;
				color: #ffd03b;
				font-family: 'pingfangSc',sans-serif;
			}
			.itemValue {
				position: absolute;
				height: 1.67vh;
				font-size: 1.48vh;
				line-height: 1.67vh;
				font-family: 'titleFont', sans-serif;
				font-weight: 400;
				letter-spacing: 1px;
				background: linear-gradient(180deg, #FFFFFF 42%, #FFD03B 100%);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}
			
		}
		
		.radarChartTextBoxTop {
			position: absolute;
			width: 100%;
			height: 11.93%;
			display: flex;
			justify-content: center;
			align-items: center;
			
			.itemNameTop {
				height: 1.944vh;
				font-size: 1.3vh;
				line-height: 1.944vh;
				font-weight: 400;
				color: #ffd03b;
				font-family: 'pingfangSc',sans-serif;
			}
			.itemValueTop {
				height: 1.67vh;
				font-size: 1.48vh;
				line-height: 1.67vh;
				font-family: 'titleFont', sans-serif;
				font-weight: 400;
				letter-spacing: 1px;
				background: linear-gradient(180deg, #FFFFFF 42%, #FFD03B 100%);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				margin-left: 1.67%;
			}
		}
	}

}
</style>

<script>
export default {
	/*
		输入数据字段：
		{
			memberName: 党员姓名（彝族党员姓名还是用汉字）
			avatarUrl: 党员照片地址
			scores：{
				theoryLevel: 理论水平,
				dedication: 奉献意识,
				behavior: 日常行为,
				exemplar: 模范作用,
				belief: 理想信念,
				ability: 业务能力
			}
		}
	*/
	props:{
		dataInput:{
			type: Object
		}
	},
	data(){
		return {
			memberInfo: {
				memberName: '',
				avatarUrl: '',
				scores: {
					theoryLevel: 0,
					dedication: 0,
					behavior: 0,
					exemplar: 0,
					belief: 0,
					ability: 0
				}
			},
			svgPath:''
		}
	},
	mounted(){
		this.memberInfo = this.dataInput;
		let {scores} = this.memberInfo;
		const pi = Math.PI;
		let y1, x2, y2, x3, y3, y4, x5, y5, x6, y6;
		y1 = (50 - 40*(scores.theoryLevel/100)).toFixed(2);
		x2 = (50 + 40*(scores.behavior/100)*Math.sin(pi/3)).toFixed(2);
		y2 = (50 - 40*(scores.behavior/100)*Math.cos(pi/3)).toFixed(2);
		x3 = (50 + 40*(scores.belief/100)*Math.sin(pi/3)).toFixed(2);
		y3 = (50 + 40*(scores.belief/100)*Math.cos(pi/3)).toFixed(2);
		y4 = (50 + 40*(scores.ability/100)).toFixed(2);
		x5 = (50 - 40*(scores.exemplar/100)*Math.sin(pi/3)).toFixed(2);
		y5 = (50 + 40*(scores.exemplar/100)*Math.cos(pi/3)).toFixed(2);
		x6 = (50 - 40*(scores.dedication/100)*Math.sin(pi/3)).toFixed(2);
		y6 = (50 - 40*(scores.dedication/100)*Math.cos(pi/3)).toFixed(2);
		let svgPath = 'M50 '+y1+' L'+x2+' '+y2+' L'+x3+' '+y3+' L50 '+y4+' L'+x5+' '+y5+' L'+x6+' '+y6+' Z';
		this.svgPath = svgPath;
		return false;
	}
}
</script>

